<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot
        ><v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParam.name" />
        <v-input label="用户手机" v-model="searchParam.mobileNum" />
        <v-select
          clearable
          placeholder="请选择"
          :options="isPartyStatus"
          v-model="searchParam.isPartyMember"
          @change="$refs.list.search()"
          label="是否党员"
        />
        <v-select
          clearable
          placeholder="请选择"
          :options="isCadre"
          v-model="searchParam.isCadre"
          @change="$refs.list.search()"
          label="是否干部"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  partyMemberUrl,
  partyMemberDetailUrl,
  editPartyMemberUrl,
  deletePartyMemberUrl,
} from "./api.js";
import { isPartyStatus, isPartyStatusMap, isCadre, isCadreMap } from "./map.js";
import {
  createAlinkVNode,
  createImgVNode,
  createHTMLVNode,
} from "@/utils/utils.js";

export default {
  name: "realNameAuditList",
  data() {
    return {
      isCadre,
      isPartyStatus,
      searchParam: {
        name: "",
        isCadre: "",
        mobileNum: "",
        isPartyMember: "",
      },

      tableUrl: partyMemberUrl,
      headers: [
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "avatarUrl",
          label: "图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "isPartyMember",
          label: "是否党员",
          formatter: (row, prop) => {
            return isPartyStatusMap[row.isPartyMember];
          },
        },
        {
          prop: "isCadre",
          label: "是否干部",
          formatter: (row, prop) => {
            return isCadreMap[row.isCadre];
          },
        },
        {
          prop: "mobileNum",
          label: "联系电话",
        },
        {
          prop: "post",
          label: "职务",
        },
        {
          prop: "dutyResp",
          label: "岗位职责",
        },
        {
          prop: "respArea",
          label: "网格管理",
        },
        {
          prop: "sortIndex",
          label: "排序",
        },
      ],
    };
  },
  computed: {},
  methods: {
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${deletePartyMemberUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },

    toAdd() {
      this.$router.push({
        name: "partyMemberForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "partyMemberForm",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
